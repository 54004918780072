in.pdf-viewer-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .pdf-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .pdf-viewer {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .pdfframe {
    width: 100%;
    height: 650px;
    border: none;
  }
  
  .download-btn {
    position: absolute;
    bottom: 10px;
    right: 19px;
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download-btn:hover {
    background-color: #0056b3;
  }
  