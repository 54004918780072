@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow);

.sectionClass {
    position: relative;
    display: block;
}

.fullWidth {
    width: 100% !important;
    display: table;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
}


.sectiontitle {
    background-position: center;
    margin: 30px 0 0px;
    text-align: center;
    min-height: 20px;
}

.sectiontitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
}


.headerLine {
    width: 160px;
    height: 2px;
    display: inline-block;
    background: #101F2E;
}


.projectFactsWrap {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
}


#projectFacts .fullWidth {
    padding: 0;
}

.projectFactsWrap .item {
    width: 25%;
    height: 100%;
    padding: 25px 0px;
    text-align: center;
}

.projectFactsWrap .item:nth-child(1) {
    background: rgb(16, 31, 46);
}

.projectFactsWrap .item:nth-child(2) {
    background: rgb(18, 34, 51);
}

.projectFactsWrap .item:nth-child(3) {
    background: rgb(21, 38, 56);
}

.projectFactsWrap .item:nth-child(4) {
    background: rgb(23, 44, 66);
}

.projectFactsWrap .item p.number {
    font-size: 40px;
    padding: 0;
    font-weight: bold;
}

.projectFactsWrap .item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin: 0;
    padding: 10px;
    font-family: 'Open Sans';
}


.projectFactsWrap .item span {
    width: 60px;
    background: rgba(255, 255, 255, 0.8);
    height: 2px;
    display: block;
    margin: 0 auto;
}


.projectFactsWrap .item i {
    vertical-align: middle;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.8);
}


.projectFactsWrap .item:hover i,
.projectFactsWrap .item:hover p {
    color: white;
}

.projectFactsWrap .item:hover span {
    background: white;
}

@media (max-width: 786px) {
    .projectFactsWrap .item {
        flex: 0 0 50%;
    }
}
